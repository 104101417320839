@import "vars";
@import "tailwind.css";
@import "~balloon-css";
@import "~@fortawesome/fontawesome-pro/css/all.css";
@import "~vue-slider-component/theme/antd.css";
@import "poppins.css";

html {
  overflow: scroll !important;
}

.slideUp {
  transition: 0.25s;
  transition-timing-function: ease-out;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#endDate {
  width: 100%;
}

#startDate {
  width: 100%;
}
.scroll-m-20 {
  scroll-margin: 5rem;
}
