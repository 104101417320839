/* poppins-100 - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/poppins-v15-latin-ext_latin_devanagari-100.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-100.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-100.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-100.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200 - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/poppins-v15-latin-ext_latin_devanagari-200.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-200.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-200.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/poppins-v15-latin-ext_latin_devanagari-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300 - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/poppins-v15-latin-ext_latin_devanagari-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/poppins-v15-latin-ext_latin_devanagari-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/poppins-v15-latin-ext_latin_devanagari-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/poppins-v15-latin-ext_latin_devanagari-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800 - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/poppins-v15-latin-ext_latin_devanagari-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-800.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-800.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900 - latin-ext_latin_devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/poppins-v15-latin-ext_latin_devanagari-900.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-900.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-ext_latin_devanagari-900.svg#Poppins') format('svg'); /* Legacy iOS */
}
